<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="plus" @click="$router.push(`new`)">Add {{ resource.name }}</sc-button>
            <sc-search :table="table" field="q"></sc-search>
        </div>

        <sc-table :table="table" v-if="table">

            <template #column-thumb="{row}">
                <div class="thumb">
                    <img :src="imageUrl(row)" v-if="row.attributes.type !== 'html'"/>
                    <div v-else class="thumb-img"><awesome-icon class="icon" :icon="['fab', 'html5']" /></div>
                </div>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false" v-if="canManage(row)" >
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-details="{row}">
                <sc-table-cell :table="table" field="details" :padded="false" :clickable="false">
                    <sc-table-button @click="mediaDetails=row" icon="eye">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)" v-if="canManage(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>
        </sc-table>

        <media-details :item="mediaDetails" v-if="mediaDetails" @close="mediaDetails=undefined"/>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
import MediaDetails from "@/views/app/media/components/media-details";

export default {
  name: "all",
  components: {MediaDetails, ScSearch},

  data() {
    return {
      table: null,
      mediaDetails: undefined
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      fetchArguments: () => {
        // return '&per_page=10'
      },
      columns: {
        thumb: {
          target: 'attributes.thumb',
          name: 'thumbnail',
          sort: false
        },
        name: {
          width: 300,
          target: 'attributes.name',
          fill: true,
          sort: false
        },
        type: {
          target: 'attributes.type',
          sort: false
        },
        description: {
          fill: true,
          target: 'attributes.description',
          sort: false
        },
        edit: {width: 70, sort: false},
        details: {width: 70, sort: false},
        delete: {width: 80, sort: false}
      },
      hover: false,
    });

    this.table.fetch()
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
        .catch((err) => {
          let message = "Something went wrong";
          if (err.response) {
            if (err.response.hasOwnProperty('message')) {
              message = err.response.message;
            } else if (err.response.hasOwnProperty('data') && err.response.data.hasOwnProperty('message')) {
              message = err.response.data.message;
            }
          }
          this.$notify.error(message)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit`)
    },

    imageUrl(row) {
      return `${process.env.VUE_APP_AWS_URL}${row.attributes.thumb}`
    },

    canManage(row) {
      if (!row.hasOwnProperty('created_by')) {
        return true;
      } else {
        const group_id = row.created_by.data.user_group.data.id;
        return this.$user.isAtLeast(1) || this.$user.belongsToGroup(group_id);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.thumb {
    width: 100%;
    height: 100px;
    max-height: 100px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .thumb-img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        text-align: center;

        .icon {
            font-size: 3em;
            margin: .5em auto 0;
        }
    }
}
</style>