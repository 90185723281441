var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header pd"},[_c('sc-button',{attrs:{"icon":"plus"},on:{"click":function($event){return _vm.$router.push("new")}}},[_vm._v("Add "+_vm._s(_vm.resource.name))]),_c('sc-search',{attrs:{"table":_vm.table,"field":"q"}})],1),(_vm.table)?_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-thumb",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"thumb"},[(row.attributes.type !== 'html')?_c('img',{attrs:{"src":_vm.imageUrl(row)}}):_c('div',{staticClass:"thumb-img"},[_c('awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'html5']}})],1)])]}},{key:"column-edit",fn:function(ref){
var row = ref.row;
return [(_vm.canManage(row))?_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"edit","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.edit(row)}}})],1):_vm._e()]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"eye"},on:{"click":function($event){_vm.mediaDetails=row}}})],1)]}},{key:"column-delete",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[(_vm.canManage(row))?_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete the "+_vm._s(_vm.resource.name)+" \""+_vm._s(row.attributes.name)+"\"? ")]):_vm._e()],1)]}}],null,false,718508516)}):_vm._e(),(_vm.mediaDetails)?_c('media-details',{attrs:{"item":_vm.mediaDetails},on:{"close":function($event){_vm.mediaDetails=undefined}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }